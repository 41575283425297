import { posAxiosClient } from '@kinderlabs-pos/apis/axios';
import {
	CursorPageType,
	GuestMemberDetailInfoType,
	GuestMemberInfoType,
	GuestMemberStatisticsType,
	PageType,
	Pageable,
	VisitUserGendersType,
} from '@kinderlabs-pos/shared-data-type';
import { Dayjs } from 'dayjs';
import { rootUrl } from '../WaiverApis/const';
import axios from 'axios';
import { setAxiosResponseInterceptor } from 'libs/apis/axios/src/libs/kinderlabs/setAxiosResponseInterceptor';

const prefix = 'guest-member';

const registerGuestMemberAsync =
	(rootUrl: string) =>
	async (
		userInfo: Partial<
			Omit<
				GuestMemberInfoType,
				| 'id'
				| 'created'
				| 'kinderlabsAgreement'
				| 'storeAgreement'
				| 'marketingAgreement'
				| 'telephone'
			>
		> & { isMarketingAgree: boolean; telephone: string }
	) =>
		(await posAxiosClient.post<GuestMemberInfoType>(`${rootUrl}/${prefix}`, userInfo)).data;

type GetMemberInfoType = { type: 'telephone'; value: string } | { type: 'id'; value: number };
const getGuestMemberInfoAsync =
	(rootUrl: string) =>
	async ({ type, value }: GetMemberInfoType) =>
		(await posAxiosClient.get<GuestMemberInfoType>(`${rootUrl}/${prefix}/${type}/${value}`)).data;

const updateGuestMemberInfoAsync =
	(rootUrl: string) =>
	async (
		userInfo: Partial<
			Omit<
				GuestMemberInfoType,
				| 'kinderlabsAgreement'
				| 'storeAgreement'
				| 'marketingAgreement'
				| 'stroeId'
				| 'id'
				| 'telephone'
			>
		> & { isMarketingAgree: boolean; id: number; telephone: string }
	) =>
		(await posAxiosClient.put<GuestMemberInfoType>(`${rootUrl}/${prefix}`, userInfo)).data;

const deleteGuestMemberInfoAsync = (rootUrl: string) => async (idList: number[]) =>
	(
		await posAxiosClient.delete(`${rootUrl}/${prefix}`, {
			params: {
				idList,
			},
		})
	).data;

type GuestMemberInfoListAsyncRequestParams = {
	storeIdList?: number[];
	gender?: VisitUserGendersType;
	search?: string;
	pageable?: Pageable;
	from?: Dayjs;
	to?: Dayjs;
};

const getGuestMemberInfoListAsync =
	(rootUrl: string) =>
	async ({ pageable, from, to, gender, ...others }: GuestMemberInfoListAsyncRequestParams) =>
		(
			await posAxiosClient.get<PageType<GuestMemberInfoType>>(`${rootUrl}/${prefix}/search`, {
				params: {
					...others,
					genderType: gender,
					...pageable?.getPageableQueryParam(),
					from: from?.format('YYYY-MM-DD HH:mm:ss'),
					to: to?.format('YYYY-MM-DD HH:mm:ss'),
				},
			})
		).data;

const getGuestMemberInfoListAsyncInfinite =
	(rootUrl: string) =>
	async ({
		pageable,
		from,
		to,
		gender,
		cursorId,
		...others
	}: GuestMemberInfoListAsyncRequestParams & { cursorId?: string | number }) =>
		(
			await posAxiosClient.get<CursorPageType<GuestMemberInfoType>>(
				`${rootUrl}/${prefix}/search/cursor`,
				{
					params: {
						...others,
						cursorId,
						genderType: gender,
						...pageable?.getPageableQueryParam(),
						from: from?.format('YYYY-MM-DD HH:mm:ss'),
						to: to?.format('YYYY-MM-DD HH:mm:ss'),
					},
				}
			)
		).data;

const getGuestMemberStatistics = (rootUrl: string) => async (memberId: number) =>
	(
		await posAxiosClient.get<GuestMemberStatisticsType>(`${rootUrl}/${prefix}/statistics`, {
			params: {
				memberId,
			},
		})
	).data;

const getGuestMemberDetailInfoAsync = (rootUrl: string) => async (id: number) =>
	(
		await posAxiosClient.get<GuestMemberDetailInfoType>(`${rootUrl}/${prefix}/detail`, {
			params: {
				id,
			},
		})
	).data;

const getRecentFiveGuestMemberInfo = (rootUrl: string) => async (storeId?: number) =>
	(
		await posAxiosClient.get<GuestMemberInfoType[]>(`${rootUrl}/${prefix}/list/recent`, {
			params: {
				storeId,
			},
		})
	).data;

const createVisitHistoryAsync =
	(rootUrl: string) =>
	async ({
		storeId,
		memberId,
		visitDate,
	}: {
		storeId: number;
		memberId: number;
		visitDate: string;
	}) =>
		(
			await posAxiosClient.post(`${rootUrl}/${prefix}/visit`, null, {
				params: {
					storeId,
					memberId,
					visitDate,
				},
			})
		).data;

const deleteVisitHistoryAsync =
	(rootUrl: string) =>
	async ({
		memberId,
		visitDate,
		storeId,
	}: {
		memberId: number;
		visitDate: string;
		storeId: number;
	}) =>
		(
			await posAxiosClient.delete(`${rootUrl}/${prefix}/visit`, {
				params: {
					memberId,
					visitDate,
					storeId,
				},
			})
		).data;

const issueVerifyNumberAsync =
	(rootUrl: string) =>
	async ({
		enterpriseId,
		storeId,
		telephone,
	}: {
		enterpriseId: string;
		storeId: number;
		telephone: string;
	}) => {
		const axiosInstance = axios.create({
			headers: {
				'Enterprise-Id': enterpriseId,
			},
		});

		setAxiosResponseInterceptor(axiosInstance);

		return (
			await axiosInstance.post<boolean>(`${rootUrl}/mobile-checker/personal-auth`, null, {
				params: { storeId, telephone },
			})
		).data;
	};

const verifyNumberAsync =
	(rootUrl: string) =>
	async ({
		storeId,
		telephone,
		enterpriseId,
		verifyNumber,
	}: {
		storeId: number;
		telephone: string;
		enterpriseId: string;
		verifyNumber: string;
	}) => {
		const axiosInstance = axios.create({
			headers: {
				'Enterprise-Id': enterpriseId,
			},
		});

		setAxiosResponseInterceptor(axiosInstance);

		return (
			await axiosInstance.post<{
				targetStoreId: number;
				telephone: string;
				visitList: { storeId: number; visitDate: Date }[];
			}>(`${rootUrl}/mobile-checker/personal-auth/complete`, null, {
				params: { storeId, telephone, verifyNumber },
			})
		).data;
	};

export const guestMemberApis = {
	registerGuestMember: registerGuestMemberAsync(rootUrl),
	updateGuestMemberInfo: updateGuestMemberInfoAsync(rootUrl),
	getGuestMemberInfo: getGuestMemberInfoAsync(rootUrl),
	deleteGuestMemberInfo: deleteGuestMemberInfoAsync(rootUrl),
	getGuestMemberInfoList: getGuestMemberInfoListAsync(rootUrl),
	getGuestMemberInfoListInfinite: getGuestMemberInfoListAsyncInfinite(rootUrl),
	getGuestMemberStatistics: getGuestMemberStatistics(rootUrl),
	getGeustMemberDetailInfo: getGuestMemberDetailInfoAsync(rootUrl),
	getRecentFiveInfo: getRecentFiveGuestMemberInfo(rootUrl),
	createVisitHistory: createVisitHistoryAsync(rootUrl),
	deleteVisitHistory: deleteVisitHistoryAsync(rootUrl),
	issueVerifyNumber: issueVerifyNumberAsync(rootUrl),
	verifyNumber: verifyNumberAsync(rootUrl),
};
